import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BlogList from "../../components/blogList"
import Image from "../../components/image"

const EssentialPage = () => (
  <Layout>
    <Seo title="ITドキュメント制作" />
    <main id="ww-service" data-role="service">
      <section className="ww-section-billboard IT-docs">
        <div className="billboard-position-1 billboard-copy-wrapper">
          <div className="billboard-copyText-position">
            <h2 className="main-copy">ITドキュメント制作・編集</h2>
            <p className="body-copy">
              「伝え方は変容させつつ、伝えるべき本質は決して崩さない」。ヒアリングや取材を通して本質を掴み形にするワンダウォールのドキュメント制作・編集技術をご活用ください。
            </p>
          </div>
        </div>

        <div className="billboard-service-image-bg">
        <Image filename="IT-documents-billboard.jpg" />
        </div>
      </section>

      <section className="ww-section-detail IT-docs">
        <div className="detail-position">
          <ul className="detail-item-position">
            <li className="detail-item detail-position-1">
              <figure className="detail-item-border"></figure>
              <h3 className="detail-item-header">ヒアリング・取材</h3>
              <h4 className="detail-item-copy">
                当事者の意思を掴む、周辺の事実を知る、本質をまとめる
              </h4>
            </li>

            <li className="detail-item detail-position-2">
              <figure className="detail-item-border"></figure>
              <h3 className="detail-item-header">ライティング</h3>
              <h4 className="detail-item-copy">
                言葉を紡ぐ、ビジュアルに描き起こす、映像をとらえる
              </h4>
            </li>

            <li className="detail-item detail-position-3">
              <figure className="detail-item-border"></figure>
              <h3 className="detail-item-header">オーサリング</h3>
              <h4 className="detail-item-copy">
                伝える手段を考える、要素を的確に組み合わせる
              </h4>
            </li>
          </ul>

          <div className="detail-description">
            <Image filename="IT-documents-image-cut-w800.png" />
            <div className="detail-description-copy">
              <p>
                断片化されたテキスト、写真、動画が情報として溢れるインターネットで、あなたが本当に伝えたいことはどれくらい正確に届いているでしょうか。
              </p>
              <p>
                ワンダウォールがITドキュメントを制作・編集するときに着目するのは、伝える手段や場所に応じて「伝え方は変容させつつ、伝えるべき本質は決して崩さない」ことです。ビジュアルの品質、無駄を持ち込まないレイアウト、言葉や言い回しの選択、それらすべてが一丸となったドキュメント。この着目点はSNSに流れ行く投稿も、何年にも渡って保管される紙資料でも同じく必要なことです。
              </p>
              <p>
                ヒアリングや取材を通して本質を掴み形にするワンダウォールのドキュメント制作・編集技術をご活用ください。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="ww-section-portfolio">
        <div className="detail-position">
          <article className="portfolio-item">
            <div className="portfolio-item-image-link">
              <div className="portfolio-item-image-position"></div>
              <Image filename="IT-documents-slides.jpg" />
            </div>

            <div className="portfolio-item-description">
              <span className="portfolio-item-genre">WORKS</span>
              <h3 className="portfolio-item-title">セミナー用スライド作成</h3>
              <div className="portfolio-item-copy">
                <p>
                  PowerPointやApple
                  Keynoteに最適化されたスライド制作を行います。
                </p>
                <p>
                  発信者のアイデンティティを端的に見せるカラーリング、フォントセットを施したテンプレートを開発し、ご提供します。
                </p>
                <p>
                  そして様々な用途に応じたスライド原稿、写真・イラスト作成を一括で担当いたします。
                </p>

                <p className="portfolio-item-technology">
                  <span className="line-1">主要な技術:</span>
                  <span className="line-2">
                    Keynote, PowerPoint, Photoshop, Illustrator
                  </span>
                </p>
              </div>
            </div>
          </article>

          <article className="portfolio-item">
            <div className="portfolio-item-image-link">
              <div className="portfolio-item-image-position"></div>
              <Image filename="IT-documents-techDocs.jpg" />
            </div>

            <div className="portfolio-item-description">
              <span className="portfolio-item-genre">WORKS</span>
              <h3 className="portfolio-item-title">技術文書・マニュアル制作</h3>
              <div className="portfolio-item-copy">
                <p>
                  ITの開発において技術文書やマニュアルの有無は、開発・運用の成否を大きく左右するキーポイントです。
                </p>
                <p>
                  ワンダウォールの分かりやすく整理された技術文書・マニュアルの作成ノウハウが、システム開発・運用に貢献します。
                </p>
                <p>
                  開発案件でこれらドキュメントを提出するのはもちろん、ドキュメント制作のみをお受けすることも可能です。
                </p>

                <p className="portfolio-item-technology">
                  <span className="line-1">主要な技術:</span>
                  <span className="line-2">
                    InDesign, Word, Pages, Google Docs, Dropbox Paper
                  </span>
                </p>
              </div>
            </div>
          </article>

          <article className="portfolio-item">
            <div className="portfolio-item-image-link">
              <div className="portfolio-item-image-position"></div>
              <Image filename="IT-documents-online-seminar.png" />
            </div>

            <div className="portfolio-item-description">
              <span className="portfolio-item-genre">WORKS</span>
              <h3 className="portfolio-item-title">
                オンラインセミナー向け動画制作
              </h3>
              <div className="portfolio-item-copy">
                <p>
                  新型コロナ禍の中で急速にニーズが高まっているのが、Zoomなどを活用した「非接触型」のオンラインセミナーです。
                </p>
                <p>
                  一度きりのオンラインセミナーでも、きちんと録画して見やすく編集することで、優れたコンテンツとなります。
                </p>
                <p>
                  効率的な録画の方法から、スライドやスピーカーを中心に再構成する画面制作まで、ワンダウォールはワンストップで実現します。
                </p>
                <p className="portfolio-item-technology">
                  <span className="line-1">主要な技術:</span>
                  <span className="line-2">
                    Zoom, Camtasia, PowerPoint, Keynote, YouTube, Vimeo
                  </span>
                </p>
              </div>
            </div>
          </article>
        </div>
      </section>

      <section className="ww-section-price" id="price">
        <h2 className="price-title">
          価格
          <span className="caption">*</span>
        </h2>
        <figure className="price-title-underline"></figure>

        <ul className="price-position">
          <li className="price-item price-position-1">
            <h3 className="price-item-sub-title">
              スライド制作／動画制作
            </h3>
            <p className="price-item-copy">
              オリジナル・テンプレート、スライド原稿とビジュアルの作成、動画編集、スライド・アニメーション
            </p>
            <p className="price-item-priceTag">
              月額30万円～
              <span className="caption">（税抜）</span>
            </p>
          </li>

          <li className="price-item price-position-2">
            <h3 className="price-item-sub-title">技術文書・マニュアル制作</h3>
            <p className="price-item-copy">
              要件定義書、基本設計書、ユーザーマニュアルなどテキストを中心としたドキュメント執筆
            </p>
            <p className="price-item-priceTag">
              月額20万円～
              <span className="caption">（税抜）</span>
            </p>
          </li>

          <li className="price-item price-position-3">
            <h3 className="price-item-sub-title">インタビュー取材と記事執筆</h3>
            <p className="price-item-copy">
              取材の企画立案、アポイントメント調整、インタビュー、写真撮影、記事執筆
            </p>
            <p className="price-item-priceTag">
              一本15万円～
              <span className="caption">（税抜）</span>
            </p>
          </li>

          <li className="price-item price-position-4">
            <h3 className="price-item-sub-title">IT系企画書・提案書の制作</h3>
            <p className="price-item-copy">
              要件に応じた技術調査、競合やマーケット調査、RFP（提案依頼書）作成
            </p>
            <p className="price-item-priceTag">
              一本10万円～
              <span className="caption">（税抜）</span>
            </p>
          </li>
        </ul>
        <span className="price-item legal-line">
          *
          過去の実績に基づいた参考価格です。開発の規模やスケジュールなどによって変動します。詳しくはお問い合わせください。
        </span>
      </section>
      <BlogList type="it-docs" />
    </main>
  </Layout>
)

export default EssentialPage
